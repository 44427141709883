import "./ProductsContent.css";

import {
  FaFilePdf,
  FaFileWord,
  FaFileImage,
  FaFileCsv,
  FaFileExcel,
  FaFile,
  FaChevronUp,
} from "react-icons/fa";

// import produto1 from '../../uploads/2023-07-26_P1_VP_PMUMI.pdf';
// import produto2 from '../../uploads/2023-07-26_P2_PMUMI_VP.pdf';

function ProductsContent() {
  const fileTypeToIcon = {
    pdf: FaFilePdf,
    doc: FaFileWord,
    docx: FaFileWord,
    jpg: FaFileImage,
    png: FaFileImage,
    csv: FaFileCsv,
    xls: FaFileExcel,
    xlsx: FaFileExcel,
  };

  const products = [
    {
      cardId: 1,
      cardName:
        "Seminário da Cidade - Evento de Abertura no Teatro Municipal\n",
      content: "\nEvento de Abertura no Teatro Municipal",
      // "img": `https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/09.04.2024_Apresentacao+Seminario+da+Cidade_Itajuba_versao+final.pdf`,
      // "date": "09/05/2024",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/09.04.2024_Apresentacao+Seminario+da+Cidade_Itajuba_versao+final.pdf",
          filename: "Seminário da Cidade",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 2,
      cardName: "Produto 1 - Planejamento Executivo",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.06.11_P1_VO_PDMI.pdf",
          filename: "Produto 1 - Planejamento Executivo - Versão Oficial",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 3,
      cardName: "Produto 2 - Plano de Comunicação e Participação Social",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.06.11_P2_VO_PDMI.pdf",
          filename:
            "Produto 2 - Plano de Comunicação e Participação Social - Versão Oficial",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 4,
      cardName: "Produto 3.1 - Diagnóstico Técnico",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.07.17_P3_R1_VO_PDMI.pdf",
          filename: "Produto 3.1 - Diagnóstico Técnico - Versão Oficial",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 5,
      cardName: "Produto 3.2 - Diagnóstico Participativo",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.07.17_P3_R2_VO_PDMI.pdf",
          filename: "Produto 3.2 - Diagnóstico Participativo - Versão Oficial",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 6,
      cardName: "Produto 3.3 - Relatório: 1ª Rodada de Audiências Públicas",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.07.17_P3_R3_VO_PDMI.pdf",
          filename: "Produto 3.3 - Relatório: 1ª Rodada de Audiências Públicas",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 7,
      cardName:
        "Produto 3.4 - Relatório: Atas da 1ª Rodada de Audiências Públicas",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.07.21_P3_R4_VO_PDMI.pdf",
          filename:
            "Produto 3.4 - Relatório: Atas da 1ª Rodada de Audiências Públicas",
          extension: "pdf",
        },
      ],
    },
    {
      cardId: 8,
      cardName: "Produto 4 - Prognóstico",
      files: [
        {
          path: "https://dacengenharia.s3.sa-east-1.amazonaws.com/PDMI/Produtos/2024.08.07_P4_VO_PDMI.pdf",
          filename: "Produto 4 - Prognóstico - Versão Oficial",
          extension: "pdf",
        },
      ],
    },
  ];

  const handleCardChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.checked = !e.target.checked;
    let cardId = e.target.id.split("-")[1];
    const cardList = document.getElementById("cardList-" + cardId);
    const cardContent = document.getElementById("cardContent-" + cardId);
    const cardChevron = document.getElementById("cardChevron-" + cardId);

    let currentRotation =
      parseInt(
        cardChevron.style.transform.replace("rotate(", "").replace("deg)", ""),
        10
      ) || 0;

    if (cardContent.style.maxHeight === "100vh") {
      cardContent.style.maxHeight = "0vh";
      cardChevron.style.transform = `rotate(${currentRotation - 180}deg)`;
      cardList.style.borderRadius = "10px";
      cardList.style.boxShadow = "0px 6px 2px 0px rgb(150, 140, 140)";
    } else {
      cardList.style.borderRadius = "10px 10px 0px 0px";
      cardContent.style.maxHeight = "100vh";
      cardChevron.style.transform = `rotate(${currentRotation + 180}deg)`;
      cardList.style.boxShadow = "0px 0px 0px 0px ";
    }
  };

  return (
    <div className='productsContent'>
      <div className='productsTitle'>
        <h1>DOCUMENTOS OFICIAIS</h1>
        <h2>Em breve o conteúdo elaborado até o momento do Plano!</h2>
      </div>
      <div className='center'>
        {(() => {
          if (products) {
            return products.map((product) => {
              return (
                <div key={product.cardId} className='cardProduct'>
                  <label className='cardList' id={"cardList-" + product.cardId}>
                    <div className='cardTitle'>
                      <h2>{product.cardName}</h2>
                    </div>
                    <FaChevronUp
                      className='cardChevron'
                      id={"cardChevron-" + product.cardId}
                    />
                    <input
                      type='checkbox'
                      name='card-toggle'
                      id={"cardToggle-" + product.cardId}
                      onChange={(e) => handleCardChange(e)}
                      hidden
                      defaultChecked='true'
                    />
                  </label>
                  <div
                    className='cardContent'
                    id={"cardContent-" + product.cardId}
                  >
                    <ul className='fileList'>
                      {(() => {
                        if (product.files) {
                          return product.files.map(
                            ({ path, filename, extension }) => {
                              const Icon = fileTypeToIcon[extension] || FaFile;
                              return (
                                <li className='fileListItem'>
                                  {Icon && <Icon />}
                                  <div className='fileTitle'>
                                    <a href={path} target='blank' download>
                                      <h4>{filename}</h4>
                                    </a>
                                  </div>
                                </li>
                              );
                            }
                          );
                        }
                      })()}
                    </ul>
                  </div>
                </div>
              );
            });
          }
        })()}
      </div>
    </div>
  );
}

export default ProductsContent;
